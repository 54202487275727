.App{
    padding-left:2rem;
    padding-right:2rem;
}



.drawer-content{
    padding: 1rem 3rem 3rem 3rem;
}

.drawer-input{
    margin-top: 1rem;
    width:100%;
}


.save-button-container{
    margin-top: 1rem;
}

.data-table{
    width:100%;
    background-color: #fff;
    color: #333;
    margin: none;
    padding: 0;
    border-spacing: 10px;
    border-radius: 5px;
}

.data-table td{
    padding:5px 10px 5px 10px;

}

.data-table thead{
    border-radius: 5px;
    font-weight: bolder;
    border-bottom: 1px solid #333;
    background-color: #888;
    color: #fff;
}

.data-table .material-icons{
    margin-right: 10px;
}

.data-table .material-icons:hover{
    color: #333;
}

.data-table .action-cell{
    width:20%;
    text-align: center;
    color: #ccc;
}


.modal-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    padding: 25px;
    border: none;

}

.center{
    text-align: center;
}

.margin{
    margin: 10px !important;
}

.bottom-drawer{
    border-radius: 5px 5px 0px 0px !important;
}
