.header-section{
    width: 100%;
    position:fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    padding-top: .5rem;
    padding-left: .5rem;
    justify-content: right;
}

.header-icon{
    margin-bottom: -6px;
    z-index: 100;
}

.header-item{

    color: black;
    opacity: .2;
    padding-right: 25px;
   
}

.header-item:hover{

   
    opacity: .7;
}