.navigation-section{
    display:flex;
    width:100vw;
    position: fixed;
    bottom:0px;
    background-color: #fff;
    left:0;
    color: #000;

}

.navigation-item{
    display: block ;
    width: 25%;
    text-align: center;
}



.nav-span{
    
    color: #fff;
}

.nav-icon{
    margin-bottom: -6px;
}

.navigation-div{
    height: 100%;

padding:12px;
font-size: 1rem;
}

