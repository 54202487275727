body {
  background-color: #Fff;
}

.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0px !important;
  position: absolute;
  top: 0;
  left: 0;

}

.app-container {
  padding: 25px 25px 125px 25px;
}

.main-container {
  background-color: #dfdfdf;
  width: 66vw;
  height: 66vh;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  color: #000;
}

.right-container {
  width: 37.5%;
  height: 100%;
  background-color: #fff;
  font-size: 1rem;
}

.left-container {
  width: 62.5%;
  height: 100%;
  background-color: #cde7f6;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.right-top-container {
  height: 62.5%;
  width: 100%;
  background-color: #fee2d6;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: .7rem;
  flex-direction: column;
}

.right-bottom-container {
  height: 37.5%;
  width: 100%;
  background-color: #d297b1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-size: .6rem;
  flex-direction: column;
}

.pastel-red-bg {
  background-color: #d297b1;
}

.pastel-orange-bg {
  background-color: #fee2d6;
}


.pastel-blue-bg {
  background-color: #cde7f6;
}

.gray-bg {
  background-color: lightgray;
}

.box-header {
  font-size: 2em;
  text-align: center;
}

a:visited {
  color: inherit;
}

a {
  color: #000;
}

h1 {
  color: #000;
  font-size: 1.5rem;
}


.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  border-radius: 5px 5px 0px 0px !important;
}

.stats-box {
  text-align: center;
  width: 100%;
  font-size: 2em;
  opacity: 50%;
}


.add-form {
  width: 100%;
}

.add-form-container {
  display: flex;
}

.add-form-container>div {
  width: 33%;
}

.add-image-container {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  text-align: center;
 
}

.add-photo-icon {
  font-size: 8rem !important;
  opacity: .3;
  position: absolute;
  top: 50%;

  transform: translate(-50%, -50%);
}

.image-box {
  width: 100%;
  height: 100%;
  background-color: #dfdfdf;
  position: relative;
}


.inventory-section{
  display:flex;
  justify-content: center;
  align-items: center;
 
}


.inventory-section div{
 
} 

.inventory-icon{
  font-size: 1.5rem !important;
  margin-right: 0px !important;
  margin-top: 2px;
}

.inventory-count{
  font-size: 1.4rem !important;
  padding-left: .7rem;
  padding-right: .7rem;
}

.remove-inventory-list{
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}


.not-for-mobile{
  display: table-cell;
 }

 .for-mobile{
  display: none;
 }

 .sub-text{
  font-size: .7rem;
  color: #aaa;
 }

 .data-table .action-cell{
  width:20%;
  text-align: center;
  color: #ccc;
}

.action-line{
  color: #ccc;
}

.add-fab{
  position: fixed !important;
  bottom: 6rem  !important;
  right: 25px  !important;
}

/******************** FOR MOBILE SCREENS ***********************/

@media (max-width: 500px) {

  .for-mobile{
    display: table-cell;
   }

   .not-for-mobile{
    display: none;
   }

  .add-image-container {
    padding-left: 0px;
    padding-right: 0px;
    height: 130px;
  }

  .add-form-container {
    flex-wrap: wrap;
  }

  .add-form-container>div {
    width: 100%;
  }

  .main-container {
    width: 100vw;
    height: 100vh;
    flex-wrap: wrap;
  }

  .right-container {
    width: 100%;
    height: 66%;
    background-color: #fff;
    font-size: 1rem;
  }

  .left-container {
    width: 100%;
    height: 33%;
  }

  .right-top-container {
    height: 50%;
    width: 100%;

    font-size: 1rem;
  
  }

  .right-bottom-container {
    height: 50%;
    width: 100%;

    font-size: 1rem;
 
  }

  p{
    margin-top: .3em;
    margin-bottom: .1em;
  }

}