#loginForm{
    width:50%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    
    
    transform: translate(-50%, -50%);
}

#loginForm .input-row{
    padding-top: 15px;
}